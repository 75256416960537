import "owl.carousel"
import moment from "moment/moment";


jQuery(document).ready(function ($) {

  // MUSIC CAROUSEL ITEMS
  const covers = [
    { 'link': 'https://VAVO.lnk.to/KYWMAW', 'img-uri': './dist/img/covers/VAVO_IanHarrison_KYWM.jpg', 'alt-text': 'Keep You With Me', 'remix': false },
    { 'link': 'https://vavo.lnk.to/ComebackKid', 'img-uri': './dist/img/covers/comeback-kid.jpg', 'alt-text': 'Comeback Kid', 'remix': false },
    { 'link': 'https://VAVO.lnk.to/ReverseCowgirlAW', 'img-uri': './dist/img/covers/cover-reversecowgirl.jpg', 'alt-text': 'Reverse Cowgirl', 'remix': false },

    { 'link': 'https://KB.lnk.to/MOIRemixesAW', 'img-uri': './dist/img/covers/cover-kb-miles-on-it.jpg', 'alt-text': 'Miles On It', 'remix': true },
    { 'link': 'https://vavo.ffm.to/beautiful-as-you-remix', 'img-uri': './dist/img/covers/beautifulasu.jpg', 'alt-text': 'Beautiful As You', 'remix': true },
    { 'link': 'https://m10.lnk.to/WGHRemix', 'img-uri': './dist/img/covers/wegothistory.jpg', 'alt-text': 'We Got History', 'remix': true },
    { 'link': 'https://kb.lnk.to/ICFIVAVO', 'img-uri': './dist/img/covers/icanfeelit.jpg', 'alt-text': 'I Can Feel It', 'remix': true },
    { 'link': 'https://davidj.lnk.to/AWBURemix', 'img-uri': './dist/img/covers/awbu.jpg', 'alt-text': 'After We Broke Up', 'remix': true },
    { 'link': 'https://ns.lnk.to/WOFremix', 'img-uri': './dist/img/covers/worldonfire.jpg', 'alt-text': 'World On Fire', 'remix': true },
    { 'link': 'https://vavo.ffm.to/one-of-them-girls', 'img-uri': './dist/img/covers/cover-one-of-them-girls.png', 'alt-text': 'One of Them Girls', 'remix': true },
  ]; 

  // { 'link': 'https://KB.lnk.to/MOIRemixes', 'img-uri': './dist/img/covers/milesonyou.jpg', 'alt-text': 'Miles on You Remix', 'remix': true },
  // { 'link': 'https://vavo.ffm.to/this-towns-been-too-good-too-us-remix', 'img-uri': './dist/img/covers/toogood.jpg', 'alt-text': 'This Towns Been Too Good To Us', 'remix': true },
  // { 'link': 'https://vavo.ffm.to/one-of-them-girls-remix', 'img-uri': './dist/img/covers/oneofthemgirls.jpg', 'alt-text': 'One Of Them Girls', 'remix': true },
  // { 'link': 'https://vavo.ffm.to/fast-car-remix', 'img-uri': './dist/img/covers/lukecombs.jpg', 'alt-text': 'Luke Combs - Vavo Remix', 'remix': true },
  // { 'link': 'https://strm.to/SpringBreakRemixes', 'img-uri': './dist/img/covers/springbreak23remix.jpg', 'alt-text': 'Spring Break 23 Remixes', 'remix': true },
  // { 'link': 'https://hypeddit.com/morganwallen/lastnightvavoxdlayremix', 'img-uri': './dist/img/covers/VAVO-LASTNIGHT.jpg', 'alt-text': 'Last Night', 'remix': true },
  // { 'link': 'https://vavo.ffm.to/cowgirls-vavo-moonlght-remix', 'img-uri': './dist/img/covers/cowgirls.jpg', 'alt-text': 'Cowgirls', 'remix': false },
  // { 'link': 'https://vavo.ffm.to/thinkin-bout-me-vavoxmoonlight', 'img-uri': './dist/img/covers/mw-thinkin-bout-me.jpg', 'alt-text': 'Thinkin Bout Me', 'remix': false },
  // { 'link': 'https://kessrecords.ffm.to/vavo-waitin-on-it', 'img-uri': './dist/img/covers/waitinonit.jpg', 'alt-text': 'Waitin On It', 'remix': false },
  // { 'link': 'https://kessrecords.ffm.to/take-me-home-vip', 'img-uri': './dist/img/covers/takemehome.jpg', 'alt-text': 'Take Me Home - VIP Mix', 'remix': true },
  // { 'link': 'https://kessrecords.ffm.to/take-me-home', 'img-uri': './dist/img/covers/takemehomeclara.jpg', 'alt-text': 'Take Me Home', 'remix': false },
  // { 'link': 'https://vavo.ffm.to/good-times-go-by-too-fast-remix', 'img-uri': './dist/img/covers/gtgbtf.webp', 'alt-text': 'Good Times Go By Too Fast', 'remix': true },
  // { 'link': 'https://kessrecords.ffm.to/pieces', 'img-uri': './dist/img/covers/pieces.webp', 'alt-text': 'Pieces', 'remix': false },
  // { 'link': 'https://kessrecords.ffm.to/rightnow', 'img-uri': './dist/img/covers/right-now.webp', 'alt-text': 'Right Now', 'remix': false },

  // MUSIC CAROUSEL BUILDER
  function populateCarousel(coversArray, targetElement) {
    $(targetElement).empty(); // Clear existing content
    $.each(coversArray, function (index, item) {
      let htmlElement = `
        <div class="item">
          <img src="${item['img-uri']}" alt="${item['alt-text']}">
          <p>${item['alt-text']}</p>
          <a class="btn" href="${item['link']}" target="_blank">Listen</a>
        </div>
      `;
      $(targetElement).append(htmlElement);
    });
  }

  const originals = covers.filter(item => !item.remix);
  const remixes = covers.filter(item => item.remix);

  populateCarousel(originals, '#carousel-original');
  populateCarousel(remixes, '#carousel-remixes');


  function createOriginalCarousel() {
    const originalCarousel = $("#carousel-original")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    originalCarousel.owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: false,
      responsive: {
        0: {
          items: 2,
        },
        700: {
          items: 3,
        },
        980: {
          items: 4
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createOriginalCarousel()

  function createRemixCarousel() {
    const remixCarousel = $("#carousel-remixes")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    remixCarousel.owlCarousel({
      loop: false,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: false,
      responsive: {
        0: {
          items: 1,
        },
        700: {
          items: 3,
        },
        980: {
          items: 4
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createRemixCarousel()


  function initScrolling(selector, textString, speed = 1) {
    const $container = $(selector);
    if (!$container.length) return;

    // Clear existing content
    $container.empty();

    // Create the scroll text element and its clone
    const $text = $('<div>', { class: 'scroll-text' });
    const $textClone = $('<div>', { class: 'scroll-text' });

    // Calculate how many words we need to fill the screen width plus some buffer
    const viewportWidth = $(window).width();
    const wordWidth = 150; // Approximate width of each word + padding
    const wordsNeeded = Math.ceil((viewportWidth * 2) / wordWidth);

    // Add words to both original and clone
    for (let i = 0; i < wordsNeeded; i++) {
      $text.append($('<span>', {
        class: 'word',
        text: textString
      }));
      $textClone.append($('<span>', {
        class: 'word',
        text: textString
      }));
    }

    // Add both elements to container
    $container.append($text);
    $container.append($textClone);

    // Adjust animation duration based on content width
    const duration = ($text.width() / speed) * 0.02; // Adjust multiplier to change speed
    $text.css('animation-duration', `${duration}s`);
    $textClone.css('animation-duration', `${duration}s`);
  }

  // Initialize scroll texts with adjusted speed
  initScrolling('#scroll-text-tour', 'TOUR', 2);
  initScrolling('#scroll-text-about', 'ABOUT', 2);



  // Additional CSS to add via jQuery for the .word class
  $("<style type='text/css'> .word { margin-right: 10px; } </style>").appendTo("head");
  // Change the 'margin-right' value to increase or decrease spacing


  // Video Carousel
  $('#video-carousel').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 3,
    startPosition: 0,
    center: true,
    responsive: {
      0: {
        margin: 20,
        items: 1
      },
      700: {
        margin: 20,
        items: 2
      },
      1100: {
        margin: 20,
        items: 3
      }
    },
    navText: [
      '<div class="owl-custom-nav-prev"><i class="far fa-chevron-left"></i></div>',
      '<div class="owl-custom-nav-next"><i class="far fa-chevron-right"></i></div>'
    ]
  })

  // // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_12586754/events?app_id=45PRESS_vavo',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      console.log(data)
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue-and-location">';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
    }
  });

  // // Scroll
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();

    var aid = $(this).attr("href");
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
  })

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("#nav").toggleClass('active')
    $(".nav-item").toggleClass('active')
    $(".line").toggleClass('active')
    if ($('#nav').hasClass('active')) {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo.png" alt="Vavo"></a>')
    } else {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo-black.png" alt="Vavo"></a>')
    }
  })

  $('.nav-item').on('click', function () {
    if ($('.nav-item').hasClass('active')) {
      $('#nav').toggleClass('active')
      $(".nav-item").toggleClass('active')
      $(".line").toggleClass('active')
    }
    if ($('#nav').hasClass('active')) {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo.png" alt="Vavo"></a>')
    } else {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo-black.png" alt="Vavo"></a>')
    }
  })

  // NEWSLETTER
  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });


  // $('#toggle-bio').on('click', function (e) {
  //   e.preventDefault();
  //   $('#bio').toggleClass('show-less')
  //   if ($('#bio').hasClass('show-less')) {
  //     $('#toggle-bio').text('READ MORE')
  //   } else {
  //     $('#toggle-bio').text('SHOW LESS');
  //   }
  // })

  // hide 'show more dates' button
  // if ($("#tour-dates").children().length < 9) {
  //   $("#toggle-dates").hide();
  // }

});